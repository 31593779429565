import { createNewTextAnimation } from "./newAnimation";
const container = document.querySelector(".container");
import { data, Card } from "./data";

let imageCanvasPromises = {};

async function getPixelColor(xPercent, yPercent, imagePath) {
	if (!imageCanvasPromises[imagePath]) {
		console.log(`Loading image ${imagePath} for the first time`);
		const loadImageAndCreateCanvas = new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = image.width;
				canvas.height = image.height;
				const context = canvas.getContext('2d');

				context!.drawImage(image, 0, 0, image.width, image.height);

				resolve(canvas);
			};

			image.onerror = (e) => reject(e);

			image.src = imagePath;
		});

		// Immediately store the loading process (Promise) in the cache
		imageCanvasPromises[imagePath] = loadImageAndCreateCanvas;
	}

	// Now, 'imageCanvasPromises[imagePath]' is always a Promise here
	const canvas = await imageCanvasPromises[imagePath];
	const context = canvas.getContext('2d');

	// Calculate the x, y based of percentage of width and height
	const xPos = Math.floor((xPercent * canvas.width) / 100);
	const yPos = Math.floor((yPercent * canvas.height) / 100);

	const pixelData = context.getImageData(xPos, yPos, 1, 1).data;

	return pixelData;
}

// Add these at the beginning of ./src/index.ts
const sampleInfoText = [
	{ position: "left", text: "Random Stat 1: 42" },
	{ position: "right", text: "Random Stat 2: 7" },
	{ position: "top", text: "Random Stat 3: 99" },
	{ position: "bottom", text: "Random Stat 4: 256" },
];

const navbar = document.querySelector(".navbar") as HTMLElement;

const exitInspectMode = (card: HTMLElement) => {
	navbar.style.setProperty("visibility", "visible")

	card.classList.remove("focused");

	//add display:none to .inspect
	const inspect = document.querySelector(".inspect");
	inspect!.classList.add("hidden");
	inspect!.classList.remove('inspect-mode');

	container!.classList.remove("inspect-mode");
	card.style.removeProperty("--center-x");
	card.style.removeProperty("--center-y");

	const otherCards = Array.from(container!.querySelectorAll("tilt-card")).filter(
		(c) => c !== card
	);

	otherCards.forEach((c, index) => {
		setTimeout(() => {
			c.classList.remove("fade-out");
			c.classList.add("fade-in");
		}, index * 0);
	});

	card.setAttribute('data-maxheight', CARD_HEIGHT_GRID);
	Array.from(card.shadowRoot!.querySelectorAll(".info-text")).forEach((textEl) => textEl.remove());
};

async function mouseIntersectsWithNonTransparentPixel(xPercentage, yPercentage, imageUrl) {
	const pixelColor = await getPixelColor(xPercentage, yPercentage, imageUrl);
	if (pixelColor[3] === 0) {
		return true;
	}
	return false;
}

const CARD_HEIGHT_GRID = '260'

function createHTMLElement(htmlString): HTMLElement {
	const template = document.createElement('template');
	template.innerHTML = htmlString.trim();
	return template.content.firstChild as HTMLElement;
}

for (let i = 0; i < data.length; i++) {
	const cardContainer = document.createElement("div");
	cardContainer.classList.add("grid-card-container");

	const cardContainerInner = document.createElement("div");
	cardContainerInner.classList.add("grid-card-container-inner");

	const shareIcon = document.createElement("div");
	shareIcon.classList.add("grid-card-share-icon");
	cardContainerInner.appendChild(shareIcon);

	const cardInfo = createHTMLElement(`
	<div>
		<div class="grid-card-title">
			Panini Select Nikola Jokic Silver Prizm
		</div>
		<div class="grid-card-price">
			1.2 ETH
		</div>
		<div class="grid-card-button-container">
			<div class="grid-card-button-sell">
			<div class="grid-card-sell-icon"></div>
			Sell</div>
			<div class="grid-card-button-redeem">Redeem</div>
		</div>
	</div> `
	);

	const title = cardInfo.querySelector(".grid-card-title") as HTMLElement;
	const price = cardInfo.querySelector(".grid-card-price") as HTMLElement;

	title.innerText = data[i].info.title;
	price.innerText = (0.5 + (Math.random() * 1.3)).toFixed(2) + ' ETH';

	const card = document.createElement("tilt-card");

	const info: Card = data[i];
	console.log(info);
	if (info) {
		card.setAttribute("data-cardurl", info.imageUrl);
		card.setAttribute("data-cutouturl", info.cutoutUrl);
		card.setAttribute("data-slaburl", info.slabUrl);
		card.setAttribute("data-raw", JSON.stringify(info));
		card.setAttribute('data-maxheight', CARD_HEIGHT_GRID);
	} else {
		throw 'info is undefined'
	}


	const rightSide = document.querySelector(".right-column") as HTMLElement;
	const leftSide = document.querySelector(".left-column") as HTMLElement;

	const playerNameDiv = document.querySelector(".player-name") as HTMLElement;
	const teamSubtitleDiv = document.querySelector(".team-subtitle") as HTMLElement;
	const teamTitleDiv = document.querySelector(".team-title") as HTMLElement;
	const teamImage = document.querySelector(".team-logo") as HTMLImageElement;
	const leftSideTable = document.querySelector(".left-side-table") as HTMLElement;
	const rightSideTable = document.querySelector(".right-side-table") as HTMLElement;

	card.addEventListener("click", function () {
		const inspectScreen = document.querySelector("inspect-screen") as HTMLElement;
		if (inspectScreen) {
			inspectScreen.remove();
		} else {
			console.log("clicked");
			const inspectScreen = document.createElement("inspect-screen");
			inspectScreen.setAttribute("data-card-info", JSON.stringify(info));
			container?.appendChild(inspectScreen);
		}
	});
	if (i === 0) {
		//todo: remove
		//card.click();
	}

	const playerCutout = card.shadowRoot!.querySelector(".player-cutout") as HTMLElement;
	const slabCutout = card.shadowRoot!.querySelector(".slab-cutout") as HTMLElement;

	//on hover over player cutout, move the card to the left and hide the team info
	playerCutout.addEventListener("mousemove", async function (event) {
		//hide the team info, which is in .right-side. animate over 1 second

		//get the pixel on player-cutout that is hovered over
		const rect = playerCutout.getBoundingClientRect();
		const x = event.clientX - rect.left; //x position within the element.
		const y = event.clientY - rect.top;  //y position within the element.
		const xPercentage = (x / rect.width) * 100;
		const yPercentage = (y / rect.height) * 100;

		const intersectsWithPlayer = await mouseIntersectsWithNonTransparentPixel(xPercentage, yPercentage, info.cutoutUrl);
		const intersectsWithSlab = await mouseIntersectsWithNonTransparentPixel(xPercentage, yPercentage, info.slabUrl);
		try {
			if (intersectsWithPlayer) {
				//leftSide.style.removeProperty("opacity");
				//playerCutout.classList.remove('highlighted')
			} else {
				//leftSide.style.setProperty("opacity", "1");
				//playerCutout.classList.add('highlighted')
			}

			if (intersectsWithSlab) {
				//rightSide.style.removeProperty("opacity");
				//slabCutout.classList.remove('highlighted')
			} else {
				//rightSide.style.setProperty("opacity", "1");
				//slabCutout.classList.add('highlighted')
			}


		} catch (e) {
			console.log('error', e);
		}
	});

	//on mouse leave, move the card back to the center and show the team info
	playerCutout.addEventListener("mouseleave", function () {
		//show the team info, which is in .right-side. animate over 1 second
		const rightSide = document.querySelector(".left-column") as HTMLElement;
		//delete opacity property
		if (rightSide)
			rightSide.style.removeProperty("opacity");
	});

	//set max height for card to 260px

	cardContainerInner.appendChild(card);
	cardContainer.appendChild(cardContainerInner);
	cardContainer.appendChild(cardInfo);
	container!.appendChild(cardContainer);
}

document.body.addEventListener('click', function (e) {
	let card = Array.from(container!.querySelectorAll("tilt-card")).find((card) => card.classList.contains("focused"));

	if (card && e.target !== card && !(card as HTMLElement).contains(e.target as Node)) {
		exitInspectMode(card as HTMLElement);
	}
});
