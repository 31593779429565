import antetopounmpoFull from "./assets/antetopounmpo_full.webp";
import antetopounmpoCutout from "./assets/antetopounmpo_cutout.webp";
import antetopounmpoSlab from "./assets/antetopounmpo_slab.webp";
import doncicFull from "./assets/doncic_full.webp";
import doncicCutout from "./assets/doncic_cutout.webp";
import doncicSlab from "./assets/doncic_slab.webp";
import jokicFull from "./assets/jokic_full.webp";
import jokicCutout from "./assets/jokic_cutout.webp";
import jokicSlab from "./assets/jokic_slab.webp";
import lebronFull from "./assets/lebron_full.webp";
import lebronCutout from "./assets/lebron_cutout.webp";
import lebronSlab from "./assets/lebron_slab.webp";
import stephenFull from "./assets/stephen_full.webp";
import stephenCutout from "./assets/stephen_cutout.webp";
import stephenSlab from "./assets/stephen_slab.webp";

import denvorNuggetsLogo from "./assets/team_logos/denver_nuggets.png";
import dallasMavericksLogo from "./assets/team_logos/mavericks_logo.png";
import losAngelesLakersLogo from "./assets/team_logos/la_lakers.png";
import milwaukeeBucksLogo from "./assets/team_logos/milwaukee_bucks.png";
import goldenStateWarriorsLogo from "./assets/team_logos/golden_state_warriors.png";

import chartPlaceholder from "./assets/chart_blue.png";

interface TableEntry {
	title: string;
	value: string;
}

export interface Player {
	name: string;
	team: string;

	subtitle1: string;
	subtitle2: string;

	teamLogoUrl: string;
	statTitle: string;
	stats: TableEntry[]
}

export interface CardInfo {
	value: string;
	title: string;
	chartImageUrl: string;
	cardTraits: TableEntry[];
}

export interface Card {
	id: string;
	info: CardInfo;
	player: Player;

	imageUrl: string;
	cutoutUrl: string;
	slabUrl: string;
}

export const data: Card[] = [
	{
		id: "panini-certified-luka-doncic-mirror-blue",
		info: {
			value: "1.7 ETH",
			chartImageUrl: chartPlaceholder,
			title: "Panini Certified Luka Donic Mirror Blue",
			cardTraits: [
				{ title: "Price", value: "190" },
				{ title: "Brand", value: "Panini" },
				{ title: "Varient", value: "Certified" },
				{ title: "Tier", value: "Mirror Blue" },
				{ title: "Grade", value: "Mint 9" },
				{ title: "Card Num", value: "153" },
				{ title: "Pop", value: "36" },
				{ title: "Serial Num", value: "70608092" },
				{ title: "Year", value: "2018" },
			],
		},
		player: {
			name: "Luka Donic",
			team: "Dallas Mavericks",
			subtitle1: "missing",
			subtitle2: "missing",
			teamLogoUrl: dallasMavericksLogo,
			statTitle: "missing",
			stats: [
				{ title: "Games Played", value: "72" },
				{ title: "PPG", value: "21.2" },
				{ title: "FG%", value: "42.7" },
				{ title: "AST", value: "6" },
				{ title: "REB", value: "7.8" },
				{ title: "BLK", value: "0.3" },
				{ title: "STL", value: "1.1" },
				{ title: "3PT%", value: "32.7" },
				{ title: "Height", value: "6' 7" },
				{ title: "Weight", value: "230" },
				{ title: "DOB", value: "2/28/1999" },
				{ title: "Draft Num", value: "3" },
				{ title: 'APG', value: '6.0' },
				{ title: 'RPG', value: '7.8' },
				{ title: 'BPG', value: '0.3' },
				{ title: 'SPG', value: '1.1' },
				{ title: '3P%', value: '0.327' },
				{ title: "MPG", value: "32.2" },
			]
		},
		imageUrl: doncicFull,
		cutoutUrl: doncicCutout,
		slabUrl: doncicSlab
	},
	{
		id: "panini-select-nikola-jokic-silver-prizm",
		info: {
			value: "1.1 ETH",
			chartImageUrl: chartPlaceholder,
			title: "Panini Select Nikola Jokic Silver Prizm",
			cardTraits: [
				{ title: "Price", value: "455" },
				{ title: "Brand", value: "Panini" },
				{ title: "Varient", value: "Select" },
				{ title: "Tier", value: "Silver Prizm" },
				{ title: "Grade", value: "Gem MT 10" },
				{ title: "Card Num", value: "30" },
				{ title: "Pop", value: "10" },
				{ title: "Serial Num", value: "75618833" },
				{ title: "Year", value: "2017" },
			],
		},
		player: {
			name: "Nikola Jokic",
			team: "Denver Nuggets",
			subtitle1: "missing",
			subtitle2: "missing",
			teamLogoUrl: denvorNuggetsLogo,
			statTitle: "missing",
			stats: [
				{ title: "Games Played", value: "75" },
				{ title: "PPG", value: "18.5" },
				{ title: "FG%", value: "49.9" },
				{ title: "AST", value: "6.1" },
				{ title: "REB", value: "10.7" },
				{ title: "BLK", value: "0.8" },
				{ title: "STL", value: "1.2" },
				{ title: "3PT%", value: "39.6" },
				{ title: "Height", value: "6' 11" },
				{ title: "Weight", value: "284" },
				{ title: "DOB", value: "2/19/1995" },
				{ title: "Draft Num", value: "41" },
			]
		},
		imageUrl: jokicFull,
		cutoutUrl: jokicCutout,
		slabUrl: jokicSlab
	},
	{
		id: "panini-select-joel-embiid-silver-prizm",
		info: {
			value: "5.1 ETH",
			chartImageUrl: chartPlaceholder,
			title: "Prizm Monopoly LeBron James",
			cardTraits: [
				{ title: "Price", value: "182" },
				{ title: "Brand", value: "Panini" },
				{ title: "Varient", value: "Monopoly" },
				{ title: "Tier", value: "Silver Prizm" },
				{ title: "Grade", value: "Gem MT 10" },
				{ title: "Card Num", value: "40" },
				{ title: "Pop", value: "4" },
				{ title: "Serial Num", value: "74839939" },
				{ title: "Year", value: "2022" }
			]
		},
		player: {
			name: "LeBron James",
			team: "LA Lakers",
			subtitle1: "missing",
			subtitle2: "missing",
			teamLogoUrl: losAngelesLakersLogo,
			statTitle: "missing",
			stats: [
				{ title: "Games Played", value: "55" },
				{ title: "PPG", value: "28.9" },
				{ title: "FG%", value: "50" },
				{ title: "AST", value: "6.8" },
				{ title: "REB", value: "8.3" },
				{ title: "BLK", value: "0.6" },
				{ title: "STL", value: "0.9" },
				{ title: "3PT%", value: "32.1" },
				{ title: "Height", value: "6' 9" },
				{ title: "Weight", value: "250" },
				{ title: "DOB", value: "12/30/1984" },
				{ title: "Draft Num", value: "1" }
			]
		},
		imageUrl: lebronFull,
		cutoutUrl: lebronCutout,
		slabUrl: lebronSlab
	},
	{
		id: "panini-instant-stephen-curry",
		info: {
			value: "0.3 ETH",
			chartImageUrl: chartPlaceholder,
			title: "Panini Instant Stephen Curry",
			cardTraits: [
				{ title: "Price", value: "300" },
				{ title: "Brand", value: "Panini" },
				{ title: "Varient", value: "Instant" },
				{ title: "Tier", value: "Versicolor" },
				{ title: "Grade", value: "Mint 9" },
				{ title: "Card Num", value: "29" },
				{ title: "Pop", value: "1" },
				{ title: "Serial Num", value: "69511270" },
				{ title: "Year", value: "2022" }
			]
		},
		player: {
			name: "Stephen Curry",
			team: "Golden State Warriors",
			subtitle1: "missing",
			subtitle2: "missing",
			teamLogoUrl: goldenStateWarriorsLogo,
			statTitle: "missing",
			stats: [
				{ title: "Games Played", value: "56" },
				{ title: "PPG", value: "29.4" },
				{ title: "FG%", value: "49.3" },
				{ title: "AST", value: "6.3" },
				{ title: "REB", value: "6.1" },
				{ title: "BLK", value: "0.4" },
				{ title: "STL", value: "0.9" },
				{ title: "3PT%", value: "42.7" },
				{ title: "Height", value: "6' 2" },
				{ title: "Weight", value: "185" },
				{ title: "DOB", value: "3/14/1988" },
				{ title: "Draft Num", value: "7" }
			]
		},
		imageUrl: stephenFull,
		cutoutUrl: stephenCutout,
		slabUrl: stephenSlab
	},
	{
		id: "panini-select-joel-embiid-silver-prizm",
		info: {
			value: "0.125 ETH",
			chartImageUrl: chartPlaceholder,
			title: "Giannis Franchise Fav-Gold",
			cardTraits: [
				{ title: "Price", value: "3400" },
				{ title: "Brand", value: "Panini" },
				{ title: "Varient", value: "Illusions" },
				{ title: "Tier", value: "Gold Franchise Favorites" },
				{ title: "Grade", value: "Gem MT 10" },
				{ title: "Card Num", value: "13" },
				{ title: "Pop", value: "3" },
				{ title: "Serial Num", value: "73710308" },
				{ title: "Year", value: "2021" }
			]
		},
		player: {
			name: "Giannis Antetokounmpo",
			team: "Milkwaukee Bucks",
			subtitle1: "Milkwaukee Bucks",
			subtitle2: "missing",
			teamLogoUrl: milwaukeeBucksLogo,
			statTitle: "missing",
			stats: [
				{ title: "Games Played", value: "67" },
				{ title: "PPG", value: "29.9" },
				{ title: "FG%", value: "55.3" },
				{ title: "AST", value: "5.8" },
				{ title: "REB", value: "11.6" },
				{ title: "BLK", value: "1.4" },
				{ title: "STL", value: "1.1" },
				{ title: "3PT%", value: "29.3" },
				{ title: "Height", value: "6' 11" },
				{ title: "Weight", value: "243" },
				{ title: "DOB", value: "12/6/1994" },
				{ title: "Draft Num", value: "15" }
			]
		},
		imageUrl: antetopounmpoFull,
		cutoutUrl: antetopounmpoCutout,
		slabUrl: antetopounmpoSlab
	}
]
